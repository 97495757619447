import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'
import {
  ConsultTicketList,
  Feedback,
  Home,
  NewsAdmin,
  Service,
  SignIn,
  Success,
  TicketDetails,
  TicketForm,
  UpdateTicket,
  AdminManagement,
  ArticlesAdmin,
  CardAdmin,
  AddForm,
  EditForm,
  NotificationPage
} from './pages'

import { AuthProvider } from './contexts/auth'
import { FormsAdmin } from 'pages/Admin/Forms/Forms'
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react'
import { InteractionStatus, InteractionType } from '@azure/msal-browser'
import { ClipLoader } from 'react-spinners'
import ConsultOldTicketList from 'pages/ConsultOldTicketList'
import OldTicketDetails from 'pages/OldTicketDetails'

export const AppRoutes = () => {
  const { error } = useMsalAuthentication(InteractionType.Redirect)
  const isAuthenticated = useIsAuthenticated()
  const { inProgress, accounts } = useMsal()

  if (!isAuthenticated && inProgress !== InteractionStatus.None) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem'
        }}
      >
        <ClipLoader color="#002356" size={35} aria-label="Loading Spinner" data-testid="loader" />{' '}
        Carregando
      </div>
    )
  }

  if (isAuthenticated && inProgress === InteractionStatus.None && accounts[0]) {
    let role = 'USER'
    if (accounts[0]?.idTokenClaims?.roles) {
      role = accounts[0]?.idTokenClaims?.roles[0] === 'writer' ? 'ADMIN' : 'USER'
    }

    const user = {
      userLogin: accounts[0].username.split('@')[0],
      userName: accounts[0].name,
      role,
      user_department: ''
    }
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('isHidden', 'false')
    localStorage.setItem('token', accounts[0]?.idToken ?? '')
  }

  return (
    <Router>
      <AuthProvider>
        <Routes>

          <Route path="/login" element={<SignIn />} />
          <Route
            path="/"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Home />
              </MsalAuthenticationTemplate>
            }
          />

          <Route
            path="/ticket/detail/:ticketId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <TicketDetails />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/ticket/list"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <ConsultTicketList />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/ticket/success/:ticketId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Success />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/ticket/feedback/:ticketId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Feedback />
              </MsalAuthenticationTemplate>
            }
          />

          <Route
            path="/ticket/new/:formId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <TicketForm />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/service/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Service />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/service/:cardIdL1/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Service />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/service/:cardIdL1/:cardIdL2/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <Service />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/updateTicket/:ticketId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <UpdateTicket />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/notification"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <NotificationPage />
              </MsalAuthenticationTemplate>
            }
          />

          <Route
            path="/admin"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <AdminManagement />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/news"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <NewsAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/faq"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <ArticlesAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/card"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <CardAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/forms"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <FormsAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/new/form"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <AddForm />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/edit/form/:formId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <EditForm />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/card/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <CardAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/card/:cardIdL1/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <CardAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path="/admin/card/:cardIdL1/:cardIdL2/:cardId"
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                <CardAdmin />
              </MsalAuthenticationTemplate>
            }
          />
          <Route
            path='/old-ticket/list'
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <ConsultOldTicketList />
            </MsalAuthenticationTemplate>
            }
          />
          <Route
            path='/old-ticket/detail/:ticketId'
            element={
              <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <OldTicketDetails />
            </MsalAuthenticationTemplate>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  )
}
