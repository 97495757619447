import { Breadcrumb, ContainerLoading, PageModel, TicketInfo } from 'components'
import { useGetOldTicketDetails } from 'hooks/useGetOldTicketDetails'
import { ContainerDetailsTicket } from 'pages/TicketDetails/styles'
import { useParams } from 'react-router-dom'

const OldTicketDetails = () => {
  const { ticketId } = useParams()
  const { oldTicket, isLoadingOldTicket } = useGetOldTicketDetails(ticketId as string)
  return (
    <PageModel title='Detalhes do Chamado'>
      <ContainerDetailsTicket>
      <Breadcrumb titlePage="VISUALIZAR SOLICITAÇÃO" goToHome={true} />
      <div className="details-ticket-content">
          {isLoadingOldTicket ? <ContainerLoading /> : <TicketInfo ticket={oldTicket} />}
        </div>
      </ContainerDetailsTicket>
    </PageModel>
  )
}

export default OldTicketDetails
