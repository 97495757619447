import styled from 'styled-components'
import { breakQueriesMaxMin, SizeBreakpoint } from 'styles/Breakpoints'

export const ContainerOldTicketsHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1rem;

  .title {
    font-size: 1.5rem;
    font-weight:bold;
  }

  /* .list-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    span {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
    }
  } */

  .search-ticket {
    .container-search {
      position: relative;
    }
    button {
      position: absolute;
      top: 20%;
      right: 5%;
      background-color: inherit;
      border: none;
      width: 1.35rem;
      height: 1.35rem;
      cursor: pointer;
      svg {
        width: 1.35rem;
        height: 1.35rem;
        color: ${({ theme }) => theme.colors.blue._800};

        &:hover {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .search-period-ticket {
    .container-input-period {
      padding: 0.5rem;
      border-radius: 0.5rem;
      height: 2.3rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      color: #707070;
      border: 1px solid #d2d2d2;
      background-color: #FFFFFF;
      span {
        font-size: 0.9rem;
      }
      button {
      background-color: inherit;
      border: none;
      width: 1.35rem;
      height: 1.35rem;
      cursor: pointer;
      svg {
        width: 1.35rem;
        height: 1.35rem;
        color: ${({ theme }) => theme.colors.blue._800};

        &:hover {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
    }
  }

  .search-requester-ticket {
    .container-input-requester {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  .input-search-requester-list {
    display: block;
    z-index: 20;
    position: absolute;
    top: 100%;
    width: 100% !important;
    height: fit-content;
    max-height: 14rem;
    overflow-y: scroll;
    color: ${({ theme }) => theme.colors.gray._500};
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    border-radius: 0 0 0.5rem 0.5rem;
    background-color: #FFFFFF;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0rem 1rem;
    background-clip: padding-box;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    ul {
      list-style-type: none;

      li {
        margin-top: 0.5rem;
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  .tickets-filters {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1rem;

    .wrapper-filters {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
    .tickets-filter {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.75rem;
      /* color: ${({ theme }) => theme.colors.white}; */
        color: ${({ theme }) => theme.colors.blue._800};
      label {
        font-weight: bold;
        text-transform: uppercase;
      }

      input[type='search'] {
        min-width: 15rem;

      }

      input[type='date'] {
        border: none;
        outline: none;
        color: #707070;
        background-color: transparent;
        width: 6.5rem;
      }
    }
  }

  ${breakQueriesMaxMin(700, SizeBreakpoint.xsm)} {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-size: 0.75;

    .tickets-filters {
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      gap: 0.75rem;
      margin-bottom: 0.75rem;
      .tickets-filter {
        color: ${({ theme }) => theme.colors.blue._800};
      }
    }

    /* .list-filter {
      margin-bottom: 1rem;
      span {
        color: ${({ theme }) => theme.colors.blue._800};
      }
    } */
  }
`
export const ContainerOldTicketsContent = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 10rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._900};
  padding: 2rem 3rem;
  border-radius: 1rem;

  .not-called {
    display: flex;
    min-height: 10rem;
    font-weight: bold;
  }

  .tickets-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;

    .ticket-item {
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.colors.gray._100};
      border-radius: 0 1.625rem 1.625rem 1.625rem;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray._300};
      }

      .ticket-item-label {
        display: flex;
        font-size: 0.75rem;
        font-weight: bold;

        span:first-child {
          width: 12rem;
          padding: 0.25rem 1rem 0.25rem 0.75rem;
          border-radius: 0px 13px 13px 0px;
          z-index: 1;
          background-color: red;
          color: ${({ theme }) => theme.colors.white};
          text-transform: uppercase;
        }

        span:last-child {
          width: 6rem;
          padding: 0.25rem 0.75rem 0.25rem 1.75rem;
          background-color: ${({ theme }) => theme.colors.gray._400};
          border-radius: 0 0.8215rem 0.8215rem 0;
          margin-left: -1rem;
          z-index: 0;
          color: ${({ theme }) => theme.colors.gray._500};
        }
      }

      .ticket-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 1rem 1.5rem;
        color: ${({ theme }) => theme.colors.gray._500};
        gap: 0.65rem;

        .content-subject {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.75rem;
          }
          span:last-child {
            font-weight: bold;
          }
        }

        .content-type {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.65rem;
          }
          span:last-child {
            font-size: 0.7rem;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .content-date {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.65rem;
          }
          span:last-child {
            font-size: 0.75rem;
            font-weight: bold;
          }
        }
        .content-requester {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.65rem;
          }
          span:last-child {
            font-size: 0.75rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .pagination-container {
    margin-top: 3rem;
    width: 16rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.sm)} {
    padding: 2rem;
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    padding: 2rem;
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
