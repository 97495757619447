import { OldTicketI, TicketI } from 'entities'
import { useGetColorFromTicketStatus } from 'hooks'
import React from 'react'
import { Link } from 'react-router-dom'
import Pagination from 'components/Pagination'
import { ContainerOldTicketsContent } from './styles'

export type IPagination = {
  next: boolean
  prev: boolean
  page: number
}

type Props = {
  ticketList: OldTicketI[]
  pagination: IPagination
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>
}

const OldTicketListAll = ({
  ticketList,
  pagination,
  setPagination
}: Props) => {
  const { getColor } = useGetColorFromTicketStatus()
  const handleNextPage = () => {
    setPagination((prev) => ({ ...prev, page: prev.page + 1 }))
  }

  const handlePreviousPage = () => {
    setPagination((prev) => ({ ...prev, page: prev.page - 1 }))
  }

  return (
    <>
      <ContainerOldTicketsContent>
        {ticketList.length === 0 && (
          <div className="not-called">
            <p>Nenhum chamado encontrado!</p>
          </div>
        )}
        {ticketList.length > 0 && (
          <div className="tickets-content">
            {ticketList
              .map((ticket) => (
                <Link
                  key={ticket.ticketID}
                  to={`/old-ticket/detail/${ticket.ticketID.toString()}`}
                  className="ticket-item"
                >
                  <div className="ticket-item-label">
                    <span
                      style={{
                        backgroundColor: getColor(ticket.status)
                      }}
                    >
                      {ticket.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? 'Em Andamento' : ticket.status}
                    </span>
                    <span>{ticket.ticketID}</span>
                  </div>
                  <div className="ticket-item-content">
                    <div className="content-subject">
                      <span>Assunto:</span>
                      <span>{ticket.title}</span>
                    </div>
                    <div className="content-requester">
                      <span>Solicitante:</span>
                      <span>{ticket.requesterName}</span>
                    </div>
                    <div className="content-date">
                      <span>Data de abertura:</span>
                      <span>{ticket.date}</span>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        )}
        <div className='pagination-container'>
          {ticketList.length > 0 && (
            <Pagination
              currentPage={pagination.page}
              onNextPage={handleNextPage}
              onPreviousPage={handlePreviousPage}
              hasNextPage={pagination.next}
              hasPreviousPage={pagination.prev}
            />
          )}
        </div>
      </ContainerOldTicketsContent>
    </>
  )
}

export default OldTicketListAll
