import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import React, { KeyboardEvent, useEffect, useState } from 'react'
import { ContainerOldTicketsHeading } from './styles'
import { UserModel } from 'entities'
import { toast } from 'react-toastify'
import { getUsersByName } from 'services/connector'

type Props = {
  setTicketById: (value: null) => void
  setPeriod: (dateStart: number, dateEnd: number) => void
  setRequester: (value: string) => void
  handleSearch: (value: string) => void
}

const OldFilters = ({
  setPeriod,
  setRequester,
  handleSearch,
  setTicketById
}: Props) => {
  const [ticketId, setTicketId] = useState('')
  const [dateStart, setDateStart] = useState('')
  const [dateEnd, setDateEnd] = useState('')

  const [userList, setUserList] = useState<UserModel[]>([])
  const [userSelected, setUserSelected] = useState<string | null>(null)
  const [userSelecteedToShow, setUserSelectedToShow] = useState<string | null>(null)
  const [userTyped, setUserTyped] = useState('')
  const [isLoadingFetchUser, setIsLoadingFetchUser] = useState(false)
  const [isUserSelected, setIsUserSelected] = useState(false)

  const convertToTimestamp = (dateString: string): number => {
    const dateTemp = new Date(dateString)
    dateTemp.setHours(0, 0, 0, 0)
    const newDateFormat = dateTemp.getTime()

    return newDateFormat
  }

  const handleFetchUser = async (value: string) => {
    try {
      const response = await getUsersByName(value)
      console.log(response)
      if (!response) {
        throw new Error()
      }

      setUserList(response)
    } catch (error) {
      setUserList([])
      toast.error('Ops 😥, ocorreu um erro ao procurar o usuário ')
    }
  }

  useEffect(() => {
    if (userTyped === '') {
      setIsLoadingFetchUser(false)
      setUserSelected(null)
      return
    }

    if (isUserSelected) {
      setIsUserSelected(false) // Reseta o estado de seleção de usuário
      return
    }
    const fetchData = setTimeout(() => {
      setIsLoadingFetchUser(true)
      handleFetchUser(userTyped)
    }, 2000)

    return () => {
      setIsLoadingFetchUser(false)
      clearTimeout(fetchData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTyped])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(ticketId)
    }
  }

  const handleClearInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Delete' || e.key === 'Backspace') {
      setUserSelected(null)
      setUserTyped('')
      setUserSelectedToShow(null)
      setRequester('')
    }
  }

  const handleUserSelected = (userLogin: string, userName: string) => {
    setUserSelected(userLogin)
    setUserList([])
    setUserTyped('')
    setRequester(userLogin)
    setUserSelectedToShow(userName)
    setIsUserSelected(true)
  }

  const handleSearchPeriod = () => {
    convertToTimestamp(dateStart)
    convertToTimestamp(dateEnd)
    setPeriod(convertToTimestamp(dateStart), convertToTimestamp(dateEnd))
  }
  return (
    <ContainerOldTicketsHeading>
      <Breadcrumb goToHome={true} />
      <span className='title'>Filtros:</span>
      <div className="tickets-filters">

        <div className="tickets-filter search-ticket">
          <label htmlFor="searchTicket">Número do chamado:</label>
          <div className="container-search">
            <input
              type="search"
              name="searchTicket"
              placeholder="Digite o número do chamado"
              onChange={(e) => {
                setTicketId(e.target.value)
              }}
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            />
            <button
              type="button"
              title="Procurar"
              onClick={() => {
                handleSearch(ticketId)
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
        </div>
        <div className="tickets-filter search-requester-ticket">
          <label htmlFor="requesterTicket">Solicitante:</label>
          <div className="container-input-requester">
            <input
              type="search"
              name="requesterTicket"
              placeholder='Digite o nome do requerente'
              value={userSelecteedToShow ?? userTyped}
              autoComplete='off'
              onChange={(e) => {
                setUserTyped(e.target.value)
                setUserSelectedToShow(null)
              }}
              onKeyDown={(e) => {
                handleClearInput(e)
              }}
            />
            {userList.length > 0 && (
              <div className="input-search-requester-list">
                <ul>
                  {userList.map((user) => (
                    <li
                      key={user.user_id}
                      onClick={() => {
                        handleUserSelected(user.user_login, user.user_full_name)
                      }}
                    >
                      {user?.user_full_name.toUpperCase()}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {userList.length === 0 && userTyped && !isLoadingFetchUser && isUserSelected && (
              <div className="input-search-requester-list">
                <div className="input-search-item">Nenhum usuário encontrado</div>
              </div>
            )}
          </div>
          <div className="tickets-filter search-period-ticket">
            <label htmlFor="periodTicket">Período:</label>
            <div className="container-input-period">
              <span>Início:</span>
              <input
                type="date"
                name="periodTicket"
                onChange={(e) => {
                  setDateStart(e.target.value)
                }}
                max='31-12-9999'
              />
              <span>Fim:</span>
              <input
                type="date"
                name="periodTicket"
                onChange={(e) => {
                  setDateEnd(e.target.value)
                }}
                max='31-12-9999'
              />
              <button
                type="button"
                title="Procurar"
                onClick={() => {
                  handleSearchPeriod()
                }}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ContainerOldTicketsHeading>
  )
}

export default OldFilters
