import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getTicket } from '../services/connector'
import { TicketModel, TicketModelCostumer, TicketFollowups } from '../entities'
import { formatTicketData } from './utils/formatTicket'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useGetTicketDetails = (called_id: string | number) => {
  const [ticket, setTicket] = useState<TicketModelCostumer | null>(null)
  const [isLoadingTicket, setLoadingTicket] = useState(false)

  const getTicketDetails = async (): Promise<void> => {
    setLoadingTicket(true)
    try {
      const response = await getTicket(called_id)
      if (!response) {
        setTicket(null)
        throw new Error()
      }
      const ticketFormatted = formatTicketData(response)
      setTicket(ticketFormatted)
    } catch (error: any) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
    } finally {
      setLoadingTicket(false)
    }
  }

  useEffect(() => {
    getTicketDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    ticket,
    isLoadingTicket
  }
}
